import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import EcoChangeWindow from "./ecoChangeWindow"
import Breadcrump from "./breadcrump"
import Footer from "./footer"
import Scrollup from "./scrollup"

// 順番崩さない↓
import "typeface-noto-serif-jp"
import "typeface-kosugi-maru"
import "./layout.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./custom.css"

const Layout = ({ location, title, children }) => {

  // ページ遷移時画面トップを表示
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
        }
      },
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`

  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&family=Noto+Serif+JP:wght@600&display=swap" rel="stylesheet" />
        <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/touch-icon-ipad.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/touch-icon-iphone-retina.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/icons/touch-icon-ipad-retina.png" />
      </Helmet>

      <Header siteTitle={data.site.siteMetadata.title} />

      <div id="pagetop"></div>

      {/* 採用ページではEcoChange小窓非表示 */}
      {location.pathname !== `/recruit/` && <EcoChangeWindow />}

      {/* TOPページではぱんくず非表示 */}
      {location.pathname !== rootPath && <Breadcrump location={location} title={title} />}

      <main>{children}</main>

      <Footer siteAuthor={data.site.siteMetadata.author} />
      <Scrollup />

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

Layout.defaultProps = {
  title: ``,
  location: {}
}

export default Layout
