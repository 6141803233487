import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import { Container, Row, Col, Button, Fade } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const EcoChangeWindow = () => {

  const [show, setShow] = useState(true)

  const data = useStaticQuery(graphql`
    query {
      ecoChangeLogo: file(relativePath: { eq: "ecochange_logo.png" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Fade in={show}>
      <div id="EcoChangeWindow">
        <Container>
            <Row className="align-items-center text-center">
              <Col lg="auto" className="pr-0">
                <Img fixed={data.ecoChangeLogo.childImageSharp.fixed}/>
                <Button
                  variant="primary" 
                  href="https://www.ecochange.jp/" 
                  target="_blank" rel="noopener noreferrer"
                >
                  詳細はこちら
                </Button>
              </Col>
              <Col className="pr-0 close">
                <Button 
                  className="p-0"
                  variant="link"
                  onClick={() => setShow(!show)}
                  aria-controls="EcoChangeWindow"
                  aria-expanded={show}
                >
                  <FontAwesomeIcon icon={faTimesCircle} /><br/>CLOSE
                </Button>
              </Col>
            </Row>
        </Container>
      </div>
    </Fade>

  )
}

export default EcoChangeWindow
