import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap'

const Header = ({siteTitle}) => {

  const [ showProduct, setShowProduct ] = useState(false)
  const [ showIntroduction,setShowIntroduction ] = useState(false)
  const [ showReqruit,setShowReqruit ] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      gwLogoOrange: file(relativePath: { eq: "gw-logo-orange.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return(
    <header>
      <Container>
        <Navbar expand="lg">
          <Navbar.Brand lg="auto">
            <Link to="/">
              <Img fixed={data.gwLogoOrange.childImageSharp.fixed} alt={siteTitle}/>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Item>
                <Link to="/" className="nav-link" activeClassName="active">TOP</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/business/" className="nav-link" activeClassName="active">事業紹介</Link>
              </Nav.Item>

              {/* Bootstrapの<NavDropdown.Item>は下記エラーが出るので使用しない
              　　<div className="dropdown-item">で代用する
                  validateDOMNesting(...): <a> cannot appear as a descendant of <a>. */}
              <NavDropdown
                title="製品・サービス"
                show={showProduct}
                onMouseEnter={() => setShowProduct(true)}
                onMouseLeave={() => setShowProduct(false)}
              >
                <div className="dropdown-item">
                  <Link to="/ecochange/" className="nav-link" activeClassName="active">EcoChange</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/mcm/" className="nav-link" activeClassName="active">GW-MCM System</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/kintia/" className="nav-link" activeClassName="active">KINTIA</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/aws/" className="nav-link" activeClassName="active">AWS</Link>
                </div>              
                <div className="dropdown-item">
                  <Link to="/partnership/" className="nav-link" activeClassName="active">パートナーシップ</Link>
                </div>
              </NavDropdown>
              <NavDropdown 
                title="会社案内"
                show={showIntroduction}
                onMouseEnter={() => setShowIntroduction(true)}
                onMouseLeave={() => setShowIntroduction(false)}
              >
                <div className="dropdown-item">
                  <Link to="/topmessage/" className="nav-link" activeClassName="active">トップメッセージ</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/policy/" className="nav-link" activeClassName="active">経営理念</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/aboutus/" className="nav-link" activeClassName="active">会社概要</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/history/" className="nav-link" activeClassName="active">沿革</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/office/" className="nav-link" activeClassName="active">事業所</Link>
                </div>
                {/* <div className="dropdown-item">
                  <Link to="/groupcompany/" className="nav-link" activeClassName="active">グループ企業</Link>
                </div> */}
              </NavDropdown>
              <NavDropdown 
                title="採用"
                show={showReqruit}
                onMouseEnter={() => setShowReqruit(true)}
                onMouseLeave={() => setShowReqruit(false)}
              >
                <div className="dropdown-item">
                  <Link to="/recruit/" className="nav-link" activeClassName="active">採用情報</Link>
                </div>
                <div className="dropdown-item">
                  <a className="nav-link" href="/recruitApplication/" target="_blank" rel="noopener noreferrer" >採用エントリーフォーム</a>
                </div>
              </NavDropdown>
              <Nav.Item>
                <Link to="/topics/" className="nav-link" activeClassName="active">トピックス</Link>
              </Nav.Item>
              <Nav.Item>
                <Button 
                  variant="info" 
                  className="ml-3"
                  href="/contact/" 
                  target="_blank" rel="noopener noreferrer"
                 >
                  お問い合わせはこちら
                </Button>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container> 
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
