import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Container } from 'react-bootstrap'

// 第三階層以上のパンくずリストの場合は修正
// ページタイトルとファイル名を事前にマッピング→graphqlを使って呼び出し？？
const Breadcrump = ({title}) => {
	return (
		<div id="breadcrump">
			<Container expand="lg">
				<p className="mb-0 mt-3">
					<Link to="/">TOP</Link>{`　〉`}{title}
				</p>
			</Container>
		</div>
	)
}

Breadcrump.propTypes = {
    title: PropTypes.string
}

Breadcrump.defaultProps = {
    title: ``
}

export default Breadcrump
